<template>
	<w-layout column fill-height>
		<v-flex shrink>
			<v-toolbar color="primary" dark flat height="78px">
				<w-layout align-center justify-space-between row>
					<w-flex grow>
						<w-layout column>
							<w-flex v-if="!workflowId" text-truncate title>{{ $t('workflows.creation_of_a_workflow') }}</w-flex>
							<w-flex v-else text-truncate title>{{ workflow && workflow.name ? workflow.name : '' }}</w-flex>
						</w-layout>
					</w-flex>
					<w-flex shrink>
						<w-btn color="white" flat icon="close" mini @click="closeDrawer()">{{ $t('actions.close') }}</w-btn>
					</w-flex>
				</w-layout>
			</v-toolbar>
		</v-flex>
		<v-flex scroll-y>
			<WorkflowForm :value="workflow" :width="width" />
		</v-flex>
	</w-layout>
</template>

<script>
import WorkflowManagerModuleGuard from '@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard'

import { mapState } from 'vuex'

export default {
	name: 'WorkflowDrawer',
	components: {
		WorkflowForm: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Workflows/WorkflowForm')
		})
	},
	mixins: [WorkflowManagerModuleGuard],
	props: {
		value: {
			default: null,
			required: false,
			type: Number
		},
		backLink: {
			default: null,
			required: false,
			type: String
		},
		width: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			workflow: null
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		workflowId: function () {
			return this.value
		}
	},
	watch: {
		value() {
			this.loadWorkflow()
		}
	},
	mounted: function () {
		this.loadWorkflow()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.WORKFLOW_CREATED, action: this.onWorkflowCreated },
			]
		},
		closeDrawer: function () {
			this.appService.goTo(this.isAccountant ? 'customer-workflows' : 'company-settings-workflows')
		},
		async loadWorkflow () {
			if (!this.workflowId) {
				this.workflow = {}
				return;
			}

			this.$emit('loading')
			try {
				this.workflow = await this.service.getWorkflow(
					this.vendorId,
					this.workflowId,
					{
						with: ['statuses']
					}
				)
			} finally {
				this.$emit('loaded')
			}
		},
		onWorkflowCreated: function () {
			if (this.backLink) {
				this.appService.goTo(this.backLink, true)
				return;
			}

			this.appService.goTo(
				{
					name: this.isAccountant ? 'customer-workflows' : 'company-settings-workflows',
				},
				true
			);
		}
	}
}
</script>
